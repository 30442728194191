import { Field, Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { t } from '../../generated/i18n';
import { only } from '../../lib/utils';
import { NumberInput } from '../forms/fields';
import { Pricing, SimplePricing } from '../product/pricing';
import { useUser } from '../user-context';
import { WishlistContext } from '../wishlist/wishlist-context';
import { useDeliveryDate } from './cart';

const getVariantFromId = (id, variants) =>
  variants.find((variant) => variant.id === id);

export const baseButtonClass =
  'rounded-md bg-actionRed px-6 py-3 text-white uppercase font-bold transition duration-200 ';

const ProductVariants = ({ product }) => {
  if (!product.variants?.length || product.variants.length === 1) {
    return <></>;
  }

  return (
    <div className='relative mt-6 inline-block w-full'>
      <Field
        as='select'
        name='id'
        className='block w-full appearance-none rounded-md border border-0.6 bg-white px-4 py-2 pr-8 text-center leading-tight hover:border-gray-500 focus:outline-none'
      >
        {product.variants.map((variant) => (
          <option key={variant.id} value={variant.id}>
            {variant.name}
          </option>
        ))}
      </Field>
    </div>
  );
};

const Compact = ({
  product,
  priceMeta,
  selectedConfig,
  setFieldValue,
  allowMultipleAdd,
}) => {
  if (!product.isAvailableForPurchase) {
    return (
      <div className='mx-auto mb-5 block'>
        <span className='mr-2 font-bold uppercase'>{t('readMore')}</span>
        <svg
          className='-mt-[2px] inline-block'
          width='14'
          height='12'
          viewBox='0 0 14 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0.418747 6.61251H10.0875C10.2669 6.61438 10.4106 6.76063 10.4094 6.94063C10.4081 7.02938 10.3712 7.11438 10.3062 7.17501L6.44375 11.0375L7.325 11.9188L13.25 5.98751L7.33125 0.0687561L6.45 0.950006L10.3125 4.81251C10.4437 4.93501 10.4506 5.14063 10.3281 5.27188C10.2675 5.33688 10.1825 5.37438 10.0937 5.37501H0.418747V6.61251Z'
            fill='currentColor'
          />
        </svg>
      </div>
    );
  }

  return (
    <>
      <div className='flex flex-grow flex-col gap-3 text-left'>
        <div className='flex items-end justify-between'>
          <div className='justify-self-start'>
            {allowMultipleAdd && (
              <>
                <Pricing
                  className='text-actionRed'
                  pricing={
                    selectedConfig.variant.pricing
                      ? selectedConfig.variant.pricing
                      : product.pricing
                  }
                />
                {priceMeta ? (
                  priceMeta
                ) : (
                  <>
                    {!product.attributes.verpackungseht?.valueSlug.includes(
                      'karton',
                    ) && (
                      <p className='text-sm'>
                        {t(`pricePer${product.productType.slug}`)}
                      </p>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className='flex flex-col items-end justify-between justify-self-end text-actionRed'>
            {!product.productType.isDigital && (
              <>
                <img src='/static/clock.svg' alt='clock' width={32} />
                <span className='text-right'>
                  {useDeliveryDate({ product })}
                </span>
              </>
            )}
          </div>
        </div>

        <div className='flex place-items-end content-end justify-between justify-items-end'>
          <div className='self-end justify-self-start pr-4'>
            {allowMultipleAdd ? (
              <NumberInput
                ariaLabelOnly
                className='w-[130px] self-end'
                label={t('quantity')}
                type='number'
                name='quantity'
                minValue={1}
                onValueChange={(v) => setFieldValue('quantity', v)}
              />
            ) : (
              <Pricing
                className='text-actionRed'
                pricing={
                  selectedConfig.variant.pricing
                    ? selectedConfig.variant.pricing
                    : product.pricing
                }
              />
            )}
          </div>
          <div className='self-end justify-self-end'>
            <button
              disabled={!product.isAvailableForPurchase}
              className={`${baseButtonClass} transition duration-200 hover:bg-black`}
              aria-label={t('addToCart')}
              title={t('addToCart')}
              type='submit'
            >
              <img
                src='/static/cart.svg'
                alt=''
                width={26}
                className='min-w-[18px] '
              />
            </button>
          </div>
        </div>
      </div>

      <ProductVariants product={product} />
    </>
  );
};

const AddToWhishList = ({ product }) => {
  const [filled, setFilled] = useState(false);

  const { addToWishlist, isInWishlist, removeFromWishlist } =
    useContext(WishlistContext);

  const onAddToWishlist = () => {
    addToWishlist({ type: product.type, id: product.id });
  };
  const onRemoveFromWishlist = () => {
    removeFromWishlist({ type: product.type, id: product.id });
  };

  const alreadyInList = isInWishlist({
    type: product.type,
    id: product.id,
  });

  useEffect(() => {
    setFilled(alreadyInList);
  }, [alreadyInList]);

  return (
    <button
      className={`mx-auto mt-3 flex items-center justify-center gap-2 transition duration-200 hover:text-green focus:outline-none`}
      onClick={only(alreadyInList ? onRemoveFromWishlist : onAddToWishlist)}
      type='submit'
    >
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4 3C4 2.44772 4.44772 2 5 2H15C15.5523 2 16 2.44772 16 3V16.9325C16 17.3564 15.5056 17.588 15.1799 17.3166L10 13L4.82009 17.3166C4.49443 17.588 4 17.3564 4 16.9325V3Z'
          stroke='black'
          strokeLinejoin='round'
          fill={filled ? 'black' : 'none'}
        />
      </svg>
      {filled ? t('removeFromWishlist') : t('addToWishlist')}
    </button>
  );
};

const Large = ({
  product,
  selectedConfig,
  setFieldValue,
  quantityDiscount,
}) => {
  return (
    <>
      <div className='border border-0.3 bg-white p-6'>
        <Pricing
          className='text-actionRed'
          pricing={
            selectedConfig.variant.pricing
              ? selectedConfig.variant.pricing
              : product.pricing
          }
        />
        <div className='flex justify-between border-b border-0.3 pb-5'>
          <div className='w-3/5 pr-1'>
            <p>{t(`pricePer${product.productType.slug}`)}</p>
            <div>
              <SimplePricing
                pricing={
                  selectedConfig.variant.pricing
                    ? selectedConfig.variant.pricing
                    : product.pricing
                }
                quantity={selectedConfig.quantity}
              />{' '}
              {t(
                `price_per_${product.productType.slug}_quantity` +
                  (selectedConfig.quantity === 1 ? '_singular' : ''),
                { quantity: selectedConfig.quantity },
              )}
            </div>
            {!product.productType.isDigital && <span>{t('in_stock')}</span>}
          </div>
          <NumberInput
            className='w-[130px] self-center'
            ariaLabelOnly
            label={t('quantity')}
            type='number'
            name='quantity'
            minValue={1}
            onValueChange={(v) => setFieldValue('quantity', v)}
          />
        </div>

        {!product.productType.isDigital && (
          <div className='mt-4 flex flex-col font-bold text-actionRed'>
            <div className='flex'>
              <img
                src='/static/clock.svg'
                alt='clock'
                width={32}
                className='mr-2'
              />
              <span>{useDeliveryDate({ product })}</span>
            </div>
            <div className='mt-4 flex'>
              <img
                src='/static/delivery.svg'
                alt='clock'
                width={32}
                className='mr-2'
              />
              <span>{t('free_delivery')}</span>
            </div>
            {quantityDiscount && (
              <div className='mt-4 flex'>
                <img
                  src='/static/batch.svg'
                  alt='clock'
                  width={24}
                  className='mr-4'
                />
                <span>{t('quantity_discount')}</span>
              </div>
            )}
          </div>
        )}

        <ProductVariants product={product} />

        <div className='mt-8 w-full text-center'>
          {product.isAvailableForPurchase ? (
            <button
              disabled={!product.isAvailableForPurchase}
              className={`${baseButtonClass} transition duration-200 hover:bg-black`}
              type='submit'
            >
              {t('addToCart')}
            </button>
          ) : (
            <div
              title={t('out_of_stock')}
              className={`${baseButtonClass} opacity-30`}
            >
              {t('out_of_stock')}
            </div>
          )}
        </div>
      </div>

      <AddToWhishList product={product} />
    </>
  );
};

const AddToCart = ({
  product,
  className = '',
  priceMeta = undefined,
  compact = true,
  allowMultipleAdd = true,
  quantityDiscount = false,
}) => {
  const { addToCart } = useUser();
  const initialQuantity = product.productType.slug === 'wein' ? 6 : 1;

  const [selectedConfig, setSelectedConfig] = useState({
    variant: product.defaultVariant,
    quantity: initialQuantity,
  });

  useEffect(() => {
    setSelectedConfig({
      variant: product.defaultVariant,
      quantity: initialQuantity,
    });
  }, [product.id]);

  if (!selectedConfig.variant) {
    return <></>;
  }

  const Inner = compact ? Compact : Large;

  return (
    <div className={className}>
      <Formik
        initialValues={{
          id: selectedConfig.variant.id,
          quantity: selectedConfig.quantity,
        }}
        validationSchema={Yup.object({
          id: Yup.string().required(t('required')),
          quantity: Yup.number().required(t('required')).min(1),
        })}
        onSubmit={(values, actions) => {
          addToCart(product, values.id, values.quantity);
          actions.setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => {
          useEffect(() => {
            setSelectedConfig({
              variant: product.variants
                ? getVariantFromId(values.id, product.variants)
                : product.defaultVariant,
              quantity: values.quantity,
            });
          }, [values]);

          return (
            <Form className='not-styled' onClick={(e) => e.stopPropagation()}>
              <Inner
                product={product}
                priceMeta={priceMeta}
                selectedConfig={selectedConfig}
                setFieldValue={setFieldValue}
                allowMultipleAdd={allowMultipleAdd}
                quantityDiscount={quantityDiscount}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddToCart;
